import React from "react"
import { motion, useViewportScroll, useTransform } from "framer-motion"

const GraphicAnimation = () => {
  const { scrollY } = useViewportScroll()
  const positive = useTransform(scrollY, [0, 375], [400, 0])
  const negative = useTransform(scrollY, [0, 375], [-540, 0])

  const triangleX = useTransform(scrollY, [0, 600], [-1080, 0])
  const triangleY = useTransform(scrollY, [0, 600], [200, 0])

  const squareX = useTransform(scrollY, [0, 475], [800, 0])
  const squareY = useTransform(scrollY, [0, 475], [-270, 0])

  const circleX = useTransform(scrollY, [0, 325], [50, 0])
  const circleY = useTransform(scrollY, [0, 325], [-70, 0])

  const opac = useTransform(scrollY, [0, 250], [0, 1])

  return (
    <div className="relative shapes-wrapper" style={{ opacity: opac }}>
      <motion.img
        className="pentagon"
        src="/static-files/graphic-animation/pentagon.svg"
        style={{ translateX: negative, translateY: negative, opacity: opac }}
      />
      <motion.img
        className="plus"
        src="/static-files/graphic-animation/plus.svg"
        style={{ translateX: positive, translateY: negative, opacity: opac }}
      />
      <motion.img
        className="triangle"
        src="/static-files/graphic-animation/triangle.svg"
        style={{ translateX: triangleX, translateY: triangleY, opacity: opac }}
      />
      <motion.img
        className="square"
        src="/static-files/graphic-animation/square.svg"
        style={{ translateX: squareX, translateY: squareY, opacity: opac }}
      />
      <motion.img
        className="circle"
        src="/static-files/graphic-animation/circle.svg"
        style={{ translateX: circleX, translateY: circleY, opacity: opac }}
      />
    </div>
  )
}

export default GraphicAnimation
